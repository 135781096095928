import { request } from "@/request/request";

export default {
  // 上传文件（七牛云）
  upload(data) {
    return request({
      url: "/company/qiniu/upload",
      method: "post",
      data,
    });
  },
};
