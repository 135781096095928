import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 获取连接ID
  getConnectId(data) {
    return request({
      url: "/sse/getConnectId",
      method: "get",
    });
  },

  // 创建sse连接
  create(data) {
    return request({
      url: "/sse/connect?" + parameterChange(data),
      method: "get",
    });
  },

  // 关闭sse连接
  close(data) {
    return request({
      url: "/sse/close?" + parameterChange(data),
      method: "get",
    });
  },

  // 发送消息
  sendMessage(data) {
    return request({
      url: `/sse/sendMessage?type=${data.type}&connectId=${data.connectId}`,
      method: "post",
      data,
    });
  },

  // 上传图片
  uploadImage(data) {
    return request({
      url: "/sse/uploadImage",
      method: "post",
      data,
    });
  },

  // 切换连接
  change(data) {
    return request({
      url: "/sse/change?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
