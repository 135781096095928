/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-03-15 09:35:16
 */
import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    redirect: "/chat",
    component: BaseLayout,
    children: [
      {
        path: "/chat",
        name: "chat",
        component: () => import("@/views/chat/index.vue"),
      },
    ],
  },
];
