/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-02-15 11:34:23
 */
import router from "@/router";
import api from "@/api";
import { defineStore } from "pinia";
import { Message } from "tdesign-vue-next";
// 引入组件库的少量全局样式变量
import "tdesign-vue-next/es/style/index.css";

const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    userName: "",
  }),

  getters: {
    userInfo(state) {
      return { ...state };
    },
  },
  actions: {
    SET_TOKEN(token) {
      this.token = token;
    },
    login(admin_name = "", password = "", captcha = "", captcha_token = "") {
      // console.log(admin_name, password, captcha);
      // if (!admin_name || !password) message.warning("请填写账号及密码", 2);
      // else {
      //   const params = { admin_name, password, captcha, captcha_token };
      //   const res = api.login(params).then((res) => {
      //     if (res) {
      //       // const { token, name } = res.data;
      //       // this.token = token;
      //       // this.userName = name;
      //       // localStorage.setItem('token', token);
      //       // localStorage.setItem('userName', name);
      //       router.push({ path: "/home" });
      //       message.success("登录成功", 2);
      //     } else return false;
      //   });
      // }
    },
    logout() {
      // api.exitAccount().then(() => {
      //   // this.token = '';
      //   // this.userName = '';
      //   // localStorage.removeItem('token');
      //   // localStorage.removeItem('userName');
      //   router.push({ path: "/user/login" });
      //   message.success("退出成功", 2);
      // });
    },
  },
});

export default useUserStore;
