/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-05-11 11:08:21
 */
import axios from "axios";
import { useUserStore } from "@/store/index";
import router from "@/router";

const request = axios.create({
  baseURL: '/api_chat',
  timeout: 60000,
  headers: {},
});

// 异常拦截处理器
const errorHandler = (error) => {
  return Promise.reject(error);
};

request.interceptors.request.use((config) => {
  const userStore = useUserStore();
  config.headers.token = userStore.token;
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  // console.log(response);
  switch (response.data.resultCode) {
    // 0：文件上传成功状态码
    case 0:
    case 200:
      return response.data;
    case 401:
      // message.warning('登录过期，请重新登录。');
      router.push({ path: "/user/login" });
      break;
    case 301:
      // message.warning(response.data.msg, 2);
      router.push({ path: "/user/login" });
      break;
    default:
      // message.warning(response.data.msg, 2);
      return response.data;
  }
}, errorHandler);

export { request };
