/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-02-24 18:15:56
 */
import { defineStore } from 'pinia';

const useCommonStore = defineStore('common', {
  state: () => ({}),

  getters: {},
  actions: {},
});

export default useCommonStore;
