import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 课类下拉列表
  courseClassList(data) {
    return request({
      url: "/sse/courseClassList?" + parameterChange(data),
      method: "get",
    });
  },

  // 课次列表
  courseChapterList(data) {
    return request({
      url: "/sse/courseChapterList?" + parameterChange(data),
      method: "get",
    });
  },

  // 讨论详情
  discussionDetail(data) {
    return request({
      url: "/company/learning/discussionDetail?" + parameterChange(data),
      method: "get",
    });
  },

  // 老师回复
  discussionAnswer(data) {
    return request({
      url: "/company/learning/discussionAnswer",
      method: "post",
      data,
    });
  },
};
