/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-03-02 09:45:42
 */
import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  MessagePlugin,
  Collapse,
  CollapsePanel,
  ImageViewer,
  Dialog,
  Loading,
  Space,
} from "tdesign-vue-next";

// 引入组件库的少量全局样式变量
import "tdesign-vue-next/es/style/index.css";

export function lazyUse(app) {
  app.use(Button);
  app.use(Form);
  app.use(FormItem);
  app.use(Input);
  app.use(Select);
  app.use(Option);
  app.use(Collapse);
  app.use(CollapsePanel);
  app.use(ImageViewer);
  app.use(Dialog);
  app.use(Loading);
  app.use(Space);

  app.config.globalProperties.$message = MessagePlugin;
}
