/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-05-11 11:09:27
 */
/**
 * @description: 内容拼接
 * @param {*} data
 * @return {*}
 */
export function parameterChange(data) {
  let res = ``;
  for (const key in data) {
    let dataKey = "";
    if (data[key] || data[key] === 0) dataKey = data[key];
    res = `${res}&${key}=${dataKey}`;
  }
  return res.substr(1);
}

// 复制文本
export function copyText(text) {
  return new Promise((resolve) => {
    var textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed"; // 使其在视口之外
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    resolve();
  });
}
