/*
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-02-14 10:34:10
 */
import { request } from '@/request/request';

export default {
  // 账号登录
  login(data) {
    return request({
      url: '/login/login',
      method: 'post',
      data,
    });
  },
};
